@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.container {
  border-top: 1px solid #333333;

  &__light {
    background: #ffffff;
    border-top: 1px solid #ececed;
  }
}

.footer {
  padding: rem(24) rem(44);
  max-width: 2000px;
  margin-left: auto;
  margin-right: auto;

  @include max-tablet {
    padding: rem(24) rem(16);
  }

  .wrapBrand {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: rem(8);
  }

  .brand {
    font-weight: 600;
    font-size: rem(14);
    line-height: rem(26);
    color: #ffffff;
    font-family: var(--sora-fonts-plus);
  }

  .year {
    font-family: var(--sora-fonts-plus);
    font-weight: 500;
    font-size: rem(14);
    line-height: rem(26);
    text-align: left;
    color: #757185;
    color: #ffffffb2;
  }

  .socialShare {
    display: flex;
    align-items: center;
    gap: rem(12);

    .link {
      :hover {
        opacity: 0.8;
      }
    }
  }

  .navLink {
    font-weight: 500;
    font-size: rem(14);
    line-height: rem(24);
    color: #ffffffb2;
    font-family: var(--chakra-fonts-sora);
    transition: all 0.1s ease;

    &:hover {
      color: #ffffff;
    }

    &__active {
      color: #ffffff;
    }
  }

  &__light {
    .brand {
      color: #000000;
    }

    .year {
      color: #757185;
    }

    .socialShare {
      .link {
        :hover {
          opacity: 0.8;
        }
      }
    }

    .navLink {
      color: #000000b2;

      &:hover {
        color: #000000;
      }

      &__active {
        color: #000000;
      }
    }
  }
}
