@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.container {
  display: flex;
  flex-direction: column;
  flex: 1;

  border: 1px solid #ececec;
  border-radius: rem(12);

  .content {
    padding: rem(12);
  }

  .wTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: rem(4);
  }

  .title {
    color: #5b5b5b;
    font-size: rem(12);
    font-weight: 500;
    line-height: rem(18);
    letter-spacing: 0em;
    text-align: left;
  }

  .tooltipWrap {
    background: #ffffff;
    padding: rem(8);
    box-shadow: 0px 2px 8px 0px #00000026;
    border: none;
    width: rem(316);

    .tooltipTitle {
      font-size: rem(12);
      font-weight: 600;
      line-height: rem(17);
      letter-spacing: 0em;
      text-align: left;
      color: #000000;
      text-transform: uppercase;
      margin-bottom: rem(12);
    }

    .tooltipContent {
      font-size: rem(14);
      font-weight: 400;
      line-height: rem(20);
      letter-spacing: 0em;
      text-align: left;
      color: #000000;
    }
  }

  .address {
    border-radius: rem(8);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: rem(8);
    cursor: pointer;
    width: fit-content;
    margin-top: rem(8);

    .icon {
      width: rem(16);
      height: rem(16);
    }

    p {
      font-size: rem(14);
      font-weight: 500;
      line-height: rem(20);
      letter-spacing: 0em;
      text-align: left;
      color: #000000;
    }
  }

  .address:hover {
    opacity: 0.8;
  }

  .wBalance {
    background: #f6f6f6;
    padding: rem(12) rem(12);
    border-bottom-right-radius: rem(12);
    border-bottom-left-radius: rem(12);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .balance {
      text-align: left;
      font-size: rem(16);
      font-weight: 500;
      line-height: rem(24);
      letter-spacing: 0em;
      color: #1c1c1c;
    }

    .getTc {
      background: #4185ec;
      padding: rem(6) rem(10);
      gap: rem(4);
      border-radius: rem(8);
      cursor: pointer;
      margin-bottom: rem(4);
      display: flex;
      flex-direction: row;
      align-items: center;

      .icon {
        width: rem(14);
        height: rem(14);
      }

      p {
        font-size: rem(12);
        font-weight: 500;
        line-height: rem(17);
        letter-spacing: 0em;
        text-align: left;
        color: #ffffff;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
