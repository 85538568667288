@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.container {
  background: #0d0e12;

  &__light {
    background: #ffffff;
  }

  &__game {
    background: #181f24;
  }

  &__ai {
    background: #1c1c1c;
  }

  .children {
    max-width: 2000px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 rem(44px);
    position: relative;

    @include max-tablet {
      max-width: 100%;
      padding: 0 rem(16);
    }
  }
}

.backgroundImg {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: rem(490);

  img {
    width: 100%;
    object-fit: cover;
  }
}

.backgroundVideo {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: rem(490);

  video {
    width: 100%;
    object-fit: cover;
  }
}

.coverVideo {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.wrapHtml {
  @include w-max(1023.98px) {
    font-size: 8px !important;
  }
}
