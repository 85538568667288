@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: rem(12);
  &_menuWrapper {
    padding: 0 !important;
    background-color: transparent !important;
    min-height: rem(40) !important;
    border: none;
  }

  .accountContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: rem(12);

    @include mobile {
      gap: rem(6);
    }
  }

  .account {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    // padding: rem(0) rem(4) rem(0) rem(4);
    gap: rem(8);
    border-radius: rem(100);
    width: rem(40);
    border: 1px solid #ffffff33;
    height: rem(40);

    p {
      font-size: rem(14);
      font-weight: 500;
      line-height: rem(20);
      letter-spacing: 0em;
      text-align: left;
      color: #ffffff;
    }

    &_bns {
      width: auto;
      padding: rem(0) rem(4) rem(0) rem(12);
    }

    &_dark {
      border: 1px solid #a09d9d;
      p {
        color: $black;
      }
    }

    &_light {
      border: 1px solid $white;
      p {
        color: $white;
      }
    }
  }

  .balance {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: rem(0) rem(12) rem(0) rem(12);
    gap: rem(8);
    border-radius: rem(100);
    height: rem(44);
    border: 1px solid #ffffff33;
    height: rem(40);

    .icon {
      width: rem(20);
      height: rem(20);
    }

    p {
      font-size: rem(14);
      font-weight: 500;
      line-height: rem(20);
      letter-spacing: 0em;
      text-align: left;
      color: #ffffff;
    }

    &_dark {
      border: 1px solid #a09d9d;
      p {
        color: $black;
      }
    }
  }

  .avatar {
    width: rem(28);
    height: rem(28);
    border-radius: rem(14);
    background: #ffffff;
  }

  .tooltipWrap {
    background: #ffffff;
    box-shadow: 0px 2px 8px 0px #00000026;
    min-width: rem(210);
    border: none;
  }
}

.btnConnect {
  border: 1px solid var(--chakra-colors-gray-200);
  border-radius: rem(100);
  background: transparent !important;
  padding: rem(0) rem(24) !important;
  height: rem(40);

  :hover {
    opacity: 0.8;
  }

  p {
    font-family: var(--chakra-fonts-sora);
    font-size: rem(12);
    font-weight: 700;
    line-height: rem(24);
    text-align: center;
    text-transform: uppercase;
  }

  @include mobile {
    padding: rem(8) rem(16) !important;
    p {
      font-size: rem(10);
    }
  }

  &_dark {
    border: 1px solid #a09d9d;
    p {
      color: #202020;
    }
  }

  &_light {
    border: 1px solid $white;
    p {
      color: $white;
    }
  }
}

.menuList {
  border-radius: rem(12) !important;
  padding-bottom: 0 !important;
  --menu-border-radius: rem(12);
  --menu-bg: #ffffff;
  --menu-shadow: -10px 0px 40px 0px #00000033 !important;
}

.wallets {
  display: flex;
  flex-direction: row;
  // align-items: center;
  // grid-template-columns: 1fr 1fr;
  padding: rem(16);
  gap: rem(16);

  min-width: rem(240);

  @include mobile {
    gap: rem(8);
    min-width: rem(240);
  }
}
