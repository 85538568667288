@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.wrapper {
  position: relative;
  align-self: flex-end;
  overflow-y: auto;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: rem(620);

  @include mobile {
    min-width: rem(360);
  }
}

.menuHeader {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  padding-top: rem(12);
}

.nbcLogo {
  font-size: rem(20);
  font-family: var(--sora-font);
  font-weight: 700;
  line-height: 20px;

  &:hover {
    text-decoration: none;
  }
}

.closeBtn {
  width: 24px;
  height: 24px;
  cursor: pointer;

  padding-right: rem(48);
  padding-top: rem(12);

  @include mobile {
    padding-right: rem(0);
    padding-top: rem(0);
  }
}

.menuBody {
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: rem(48);
  padding-left: rem(24);
  padding-right: rem(24);

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.menuItem {
  &:not(:last-child) {
    border-bottom: 1px solid #ececec;
  }
}

.menuLink {
  color: #5b5b5b;
  font-weight: 600;
  font-size: rem(16);
  line-height: rem(26);
  padding-top: rem(32);
  padding-bottom: rem(32);
  display: block;
  text-decoration: none !important;

  &.gradientText {
    background: conic-gradient(
      from 168.15deg at 46.78% 60%,
      #8f7dc2 0deg,
      #00b2d9 135deg,
      #9099fc 256.87deg,
      #d87cee 360deg
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.blackText {
    color: #000;
  }
}

.socialWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: rem(20);
  position: sticky;
  bottom: 0;
  left: 0;
}

.socialLink {
  width: rem(48);
  height: rem(48);
  border-radius: 50%;
  background: #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accordion {
  padding-top: rem(32);
  padding-bottom: rem(32);

  * {
    border: none !important;
    outline: none !important;
    background: none !important;
    box-shadow: none !important;
  }
}

.accordionHeader {
  :global(.accordion-button) {
    padding: 0;
    color: #5b5b5b;
    font-weight: 600;
    font-size: rem(16);
    line-height: rem(26);
    background: none;

    &::after {
      background-image: var(--bs-accordion-btn-icon) !important;
    }
  }
}

.soulMenuItem {
  .menuLink {
    padding: rem(16);
  }

  &:last-child {
    .menuLink {
      padding-bottom: rem(0);
    }
  }
}
