@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.container {
  position: sticky;
  top: 0;
  z-index: 9;

  &__dark {
    background: #0d0e12;
    border-bottom: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  &__light {
    background: #ffffff;
    border-bottom: none;
    box-shadow: 0px 0px 24px -6px #0000000f;
  }

  &__game {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  &__gameActive {
    background: #181f24;
    box-shadow: 0 0 24px -6px #b8fbfe40;
  }

  &__ai {
    background: #1c1c1c;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  &__home {
    border-bottom: 1px solid #ffffff26;
  }

  &__homeActive {
    background: #0d0e12;
    box-shadow: 0 0 24px -6px #b8fbfe40;
  }

  &__arcade {
    background: #000000;
  }
}

.header {
  padding: rem(0) rem(44);
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 2000px;
  margin-left: auto;
  margin-right: auto;

  @include max-tablet {
    padding: rem(24) rem(16);
  }
}

.socialShare {
  display: flex;
  gap: rem(24);
  svg,
  path {
    fill: #ffffff;
  }

  .link {
    :hover {
      opacity: 0.8;
    }
  }

  &__light {
    svg,
    path {
      fill: #000000;
    }
  }
}

.leftContent {
  display: flex;
  align-items: center;
  gap: rem(60);

  .leftMenu {
    display: flex;
    align-items: center;
    flex-direction: row;

    @include max-tablet {
      // display: none;
    }
  }

  .navLink {
    font-weight: 500;
    font-size: rem(15);
    line-height: rem(26);
    color: #ffffffb2;
    font-family: var(--chakra-fonts-sora);
    transition: all 0.1s ease;

    &:hover {
      color: #ffffff;
    }

    &__active {
      color: #ffffff;
    }

    &__builder {
      background: conic-gradient(
        from 168.15deg at 46.78% 60%,
        #8f7dc2 0deg,
        #00b2d9 135deg,
        #9099fc 256.87deg,
        #d87cee 360deg
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  &__light {
    .navLink {
      color: #000000b2;

      &:hover {
        color: #000000;
      }

      &__active {
        color: #000000;
      }
    }
  }

  @include max-tablet {
    // display: none;
  }
}

.navbarBrand {
  font-weight: 700;
  font-size: rem(22);
  line-height: rem(32);
  color: #ffffff;
  font-family: var(--chakra-fonts-sora);
  margin-right: rem(24);

  @include mobile {
    margin-right: rem(8);
    font-size: rem(18);
    line-height: rem(28);
  }

  &:hover {
    color: #ffffff;
    opacity: 0.8 !important;
  }

  &__light {
    color: #000000;
    &:hover {
      color: #000000;
      opacity: 0.8 !important;
    }
  }
}

.navBar {
  display: flex;
  align-items: center;
  gap: rem(48);
}

.navBarIndicator {
  width: rem(1);
  display: flex;
  height: rem(70);
  background: #ffffff26;
  margin: rem(0) rem(24);
  opacity: 0;

  @include max-tablet {
    height: rem(44);
    margin: rem(0) rem(0);
  }
}

.navBarRight {
  display: flex;
  align-items: center;
  gap: rem(16);

  @include mobile {
    gap: rem(8);
  }
}

.rightContent {
  display: flex;
  justify-content: flex-end;

  &_install {
    margin-left: rem(140);

    @include max-tablet {
      margin-left: rem(40);
    }
  }

  .navLink {
    font-weight: 500;
    font-size: rem(15);
    line-height: rem(26);
    color: #ffffffb2;
    font-family: var(--chakra-fonts-sora);
    transition: all 0.1s ease;

    &:hover {
      color: #ffffff;
    }

    &__active {
      color: #ffffff;
    }
  }

  .mobileMenuBtn {
    // display: none;

    @include max-tablet {
      display: list-item;
    }
  }
  &__light {
    .navLink {
      color: #000000b2;

      &:hover {
        color: #000000;
      }

      &__active {
        color: #000000;
      }
    }
  }
}

.getTc {
  padding: rem(0) rem(32);
  font-family: var(--chakra-fonts-sora);
  font-size: rem(12);
  font-weight: 700;
  line-height: rem(17);
  letter-spacing: 0em;
  text-align: center;
  border-radius: rem(100);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: rem(40);

  @include mobile {
    padding: rem(0) rem(16);
  }

  color: #000000;
  background: #ffffff;

  &_light {
    color: #ffffff;
    background: #000000;
  }
}

.getTc:hover {
  opacity: 0.8;
}
